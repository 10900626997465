import { captureException, setExtra } from '@sentry/nextjs';
import { useEffect } from 'react';
import styled from 'styled-components';
import { MainButton } from 'global-styles/MainButton';

interface Props {
  error: Error;
}

const ErrorComponent = ({ error }: Props) => {
  useEffect(() => {
    captureException(error);
    setExtra('Error sent from Error Boundary', error);
  }, [error]);

  return (
    <ErrorComponentContainer>
      <div className="error-text">Something went wrong.</div>
      <div className="error-text">Press refresh!</div>
      <MainButton
        text="Refresh"
        buttonType="default"
        onClick={() => window.history.go()}
        injectInlineStyles={{ marginTop: '20px' }}
      />
    </ErrorComponentContainer>
  );
};

export default ErrorComponent;

const ErrorComponentContainer = styled.div`
  width: 100vw;
  height: 100vh;
  flex-direction:column;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-text {
    font-size: 14px;
    margin-top: 4px;
  }
`;
