import { BB, GRAY50, GRAY80, ButtonText2 } from './index';
import {
  AriaAttributes,
  ComponentType,
  CSSProperties,
  memo,
  ReactNode,
} from 'react';
import styled, { css } from 'styled-components';

type Props<T extends 'a' | 'button'> = {
  buttonType: 'default-w' | 'default' | 'disabled';
  iconPosition?: 'left' | 'right';
  text: string;
  injectInlineStyles?: CSSProperties;
  injectCssStyles?: string[];
  tag?: ComponentType | T;
  icon?: ReactNode;
  iconWidth?: string;
  iconHeight?: string;
  changeIconColor?: boolean;
} & JSX.IntrinsicElements[T] &
  AriaAttributes;

export function MainButton<T extends 'a' | 'button'>({
  tag,
  buttonType,
  icon,
  text,
  iconPosition,
  injectInlineStyles,
  injectCssStyles,
  changeIconColor,
  iconWidth,
  iconHeight,
  ...rest
}: Props<T>) {
  return (
    <MainButtonContainer
      as={tag || 'button'}
      buttonType={buttonType}
      iconPosition={iconPosition || 'right'}
      style={injectInlineStyles}
      injectCssStyles={injectCssStyles?.reduce(
        (acc, style) => acc + ' ' + style,
        ''
      )}
      changeIconColor={changeIconColor}
      {...rest}
    >
      <>
        <ButtonText2>{text}</ButtonText2>
        {icon && (
          <span
            className="icon-wrapper"
            style={{ width: iconWidth || '12px', height: iconHeight || '12px' }}
          >
            {icon}
          </span>
        )}
      </>
    </MainButtonContainer>
  );
}

export default memo(MainButton);

const disabledStyle = css`
  cursor: not-allowed;
  color: ${GRAY80};
  border: 1px solid ${GRAY80};
  background: transparent;
  svg {
    path {
      fill: ${GRAY80};
    }
  }
`;

interface MainButtonContainerProps {
  buttonType: 'default-w' | 'default' | 'disabled';
  iconPosition: 'left' | 'right';
  changeIconColor?: boolean;
  injectCssStyles?: string;
}

const MainButtonContainer = styled.button<MainButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  white-space: nowrap;
  transition: 0.15s all;
  flex-direction: ${({ iconPosition }) =>
    iconPosition === 'right' ? 'row' : 'row-reverse'};

  ${({ buttonType, changeIconColor }) =>
    buttonType === 'default'
      ? css`
          color: ${BB};
          border: 1px solid ${GRAY50};
          background: transparent;
        `
      : buttonType === 'default-w'
      ? css`
          color: #fff;
          border: 1px solid #fff;
          background: transparent;
          svg {
            path {
              ${changeIconColor &&
              css`
                fill: #fff;
              `}
            }
          }
        `
      : disabledStyle};

  &:hover {
    background: ${BB};
    color: #fff;
    border: 1px solid ${BB};
    svg {
      path {
        fill: #fff;
      }
    }
    ${({ buttonType }) => buttonType === 'disabled' && disabledStyle};
  }
  &:active {
    color: ${GRAY80};
    background: ${BB};
    border: 1px solid ${BB};
    svg {
      path {
        fill: ${GRAY80};
      }
    }
    ${({ buttonType }) => buttonType === 'disabled' && disabledStyle};
  }

  .icon-wrapper {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${({ iconPosition }) =>
      iconPosition === 'left' ? '0 8px 0 0' : '0 0 0 8px'};
  }
  ${({ injectCssStyles }) => injectCssStyles && `${injectCssStyles}`};
`;
