import { ErrorBoundary } from '@sentry/nextjs';
import { AppProps } from 'next/app';
import Head from 'next/head';
import GlobalThemeProvider from '../styles/GlobalThemeProvider';
// import '../styles.css';
import 'assets/styles/index.css';
import ErrorComponent from 'components/ErrorComponent';
import { AppContextProvider } from 'context/AppContext';
import metaTagImg from 'public/asset/bluedot-metatag-img.png';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { QueryClientProvider } from 'react-query';
import * as gtag from '../lib/gtag';
import { PrivatePolicyContextProvider } from 'context/PrivatePolicyContext';
import { BlogContextProvider } from 'context/BlogContext';
import { queryClient } from 'react-query/queryClient';
import { EventContextProvider } from 'context/EventContext';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  useEffect(() => {
    function setVw() {
      const vw = document.documentElement.clientWidth / 100;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }

    setVw();
    window.addEventListener('resize', setVw);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    const handleRouteChange = url => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>BLUEDOT</title>
        <meta
          name="description"
          content="BLUEDOT develops Semiconductor IP on FPGA in data center to accelerate ‌Video Processing and Video ‌Encoding, delivering functions as a ‌service."
        />
        <meta property="og:image" content={metaTagImg.src} key="og:image" />
        <meta
          property="twitter:image"
          content={metaTagImg.src}
          key="twitter:image"
        />
      </Head>
      <ErrorBoundary fallback={({ error }) => <ErrorComponent error={error} />}>
        <PrivatePolicyContextProvider>
          <AppContextProvider>
            <BlogContextProvider>
              <EventContextProvider>
                <QueryClientProvider client={queryClient}>
                  <GlobalThemeProvider>
                    <Component {...pageProps} />
                  </GlobalThemeProvider>
                </QueryClientProvider>
              </EventContextProvider>
            </BlogContextProvider>
          </AppContextProvider>
        </PrivatePolicyContextProvider>
      </ErrorBoundary>
    </>
  );
};

export default MyApp;
