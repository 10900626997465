import {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from 'react';

export type StatusType = 'inprogress' | 'scheduled' | 'closed';
export type ExhibitionType = 'international' | 'domestic';
export type EventContextState = {
  currPageNum: number;
  pageSize: number;
  type: 'all' | ExhibitionType;
  search: string;
  status: 'all' | StatusType;
  prevScrollTop: number;
};
type Action = { type: 'change'; newState: Partial<EventContextState> };
type EventContextType = {
  state: EventContextState;
  dispatch: Dispatch<Action>;
};
type EventReducer = Reducer<EventContextState, Action>;

const EventContextInitialState: EventContextState = {
  currPageNum: 0,
  pageSize: 5,
  type: 'all',
  search: '',
  status: 'all',
  prevScrollTop: 0,
};
const EventContext = createContext<EventContextType>({
  state: EventContextInitialState,
  dispatch: () => {},
});

const EventContextReducer: EventReducer = (state, action) => {
  switch (action.type) {
    case 'change': {
      return { ...state, ...action.newState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action?.type || ''}`);
    }
  }
};

export const EventContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<EventReducer>(
    EventContextReducer,
    EventContextInitialState
  );

  const value: EventContextType = { state, dispatch };

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};

export const useEventContext = () => {
  const context = useContext(EventContext);

  if (context === undefined)
    throw new Error(
      'useEventContext must be used within a Event context Provider.'
    );

  return context;
};
