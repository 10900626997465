import {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from 'react';
import { PostCategory } from 'react-query/blogApi';

export type OrderType = 'date' | 'post_view_count';
export type BlogContextState = {
  currPageNum: number;
  pageSize: number;
  category: PostCategory | 'all';
  search: string;
  orderBy: OrderType;
  order: 'ASC' | 'DESC';
  prevScrollTop: number;
};
type Action = { type: 'change'; newState: Partial<BlogContextState> };
type BlogContextType = { state: BlogContextState; dispatch: Dispatch<Action> };
type BlogReducer = Reducer<BlogContextState, Action>;

const BlogContextInitialState: BlogContextState = {
  currPageNum: 1,
  pageSize: 9,
  category: 'all',
  search: '',
  orderBy: 'date',
  order: 'DESC',
  prevScrollTop: 0,
  // latestPosts: [],
};
const BlogContext = createContext<BlogContextType>({
  state: BlogContextInitialState,
  dispatch: () => {},
});

const BlogContextReducer: BlogReducer = (state, action) => {
  switch (action.type) {
    case 'change': {
      return { ...state, ...action.newState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action?.type || ''}`);
    }
  }
};

export const BlogContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<BlogReducer>(
    BlogContextReducer,
    BlogContextInitialState
  );

  const value: BlogContextType = { state, dispatch };

  return <BlogContext.Provider value={value}>{children}</BlogContext.Provider>;
};

export const useBlogContext = () => {
  const context = useContext(BlogContext);

  if (context === undefined)
    throw new Error('use context must be used within a Blog context Provider.');

  return context;
};
