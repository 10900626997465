import styled, { css } from 'styled-components';

export const H1 = styled.h1`
  font-family: 'Inter', sans-serif;
  font-family: 'Kanit', sans-serif;
  font-size: 7rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  font-weight: 500;
  @media screen and (max-width: 904px) and (min-width: 600px) {
    font-size: 32px;
  }
  @media screen and (max-width: 599px) {
    font-size: 30px;
  }
`;

export const H1KR = styled.h1`
  font-family: 'Noto Sans KR', sans-serif;
  font-family: 'Kanit', sans-serif;

  font-size: 7rem;
  line-height: 140%;
  font-weight: 500;
  letter-spacing: -0.02em;
  @media screen and (max-width: 904px) and (min-width: 600px) {
    font-size: 32px;
  }
  @media screen and (max-width: 599px) {
    font-size: 30px;
  }
`;

export const H2 = styled.h2`
  /* font-family: 'Kanit', sans-serif; */
  font-family: 'Inter', sans-serif;
  font-family: 'Kanit', sans-serif;

  font-weight: 500;
  font-size: 5.6rem;
  line-height: 120%;
  letter-spacing: -0.015em;
  @media screen and (max-width: 904px) and (min-width: 600px) {
    font-size: 28px;
  }
  @media screen and (max-width: 599px) {
    font-size: 27px;
  }
`;

export const H2KR = styled.h2`
  font-family: 'Noto Sans KR', sans-serif;

  font-weight: 500;
  font-size: 4.8rem;
  line-height: 140%;
  letter-spacing: -0.015em;
  @media screen and (max-width: 904px) and (min-width: 600px) {
    font-size: 28px;
    font-weight: 700;
  }
  @media screen and (max-width: 599px) {
    font-weight: 700;
    font-size: 27px;
  }
`;

export const H3 = styled.h3`
  font-family: 'Inter', sans-serif;
  font-family: 'Kanit', sans-serif;

  font-weight: 500;
  font-size: 4.6rem;
  line-height: 130%;
  letter-spacing: -0.015em;
  @media screen and (max-width: 1239px) and (min-width: 905px) {
    font-size: 36px;
  }
  @media screen and (max-width: 904px) and (min-width: 600px) {
    font-size: 28px;
  }
  @media screen and (max-width: 599px) {
    font-size: 26px;
  }
`;

export const H3KR = styled.h3`
  font-family: 'Noto Sans KR', sans-serif;

  font-size: 4rem;
  line-height: 150%;
  letter-spacing: -0.015em;
  font-weight: 700;
  @media screen and (max-width: 1239px) and (min-width: 905px) {
    font-size: 32px;
  }
  @media screen and (max-width: 904px) and (min-width: 600px) {
    font-size: 26px;
  }
  @media screen and (max-width: 599px) {
    font-size: 24px;
  }
`;

export const H4 = styled.h4`
  font-family: 'Inter', sans-serif;
  font-family: 'Kanit', sans-serif;

  font-size: 2.8rem;
  line-height: 130%;
  letter-spacing: -0.015em;
  font-weight: 400;
  @media screen and (max-width: 1239px) {
    font-size: 23px;
  }
`;

export const H4KR = styled.h4`
  font-family: 'Noto Sans KR', sans-serif;

  font-size: 2.6rem;
  line-height: 140%;
  letter-spacing: -0.015em;
  font-weight: 500;
  @media screen and (max-width: 1239px) {
    font-size: 21px;
    font-weight: 500;
  }
`;

export const H5 = styled.h5`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 24px;
  line-height: 140%;
  font-weight: 500;
  @media screen and (max-width: 1239px) and (min-width: 905px) {
    font-size: 20px;
  }
  @media screen and (max-width: 904px) {
    font-size: 18px;
  }
`;

export const SubtitleL = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: -0.01em;
  @media screen and (max-width: 1239px) and (min-width: 905px) {
    font-size: 22px;
  }
  @media screen and (max-width: 904px) {
    font-size: 18px;
  }
`;

export const SubtitleM = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: -0.01em;
  @media screen and (max-width: 1239px) and (min-width: 905px) {
    font-size: 17px;
  }
  @media screen and (max-width: 904px) {
    font-size: 16px;
  }
`;

export const LabelText = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  line-height: 120%;
  font-weight: 400;
  letter-spacing: -0.01em;
  @media screen and (max-width: 1239px) {
    font-size: 14px;
  }
`;

export const BodyTextL = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  line-height: 175%;
  font-size: 17px;
  letter-spacing: -0.01em;
  @media screen and (max-width: 1239px) {
    font-size: 16px;
  }
`;

export const BodyTextLStrong = styled(BodyTextL)`
  font-weight: 500;
`;

export const BodyTextM = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  line-height: 175%;
  font-size: 16px;
  letter-spacing: -0.01em;
  font-weight: 400;
  @media screen and (max-width: 1239px) {
    font-size: 15px;
  }
`;

export const BodyTextMStrong = styled(BodyTextM)`
  font-weight: 500;
`;

export const BodyTextS = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: -0.01em;
`;

export const BodyTextSStrong = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: -0.01em;
`;

export const ButtonText1 = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
`;

export const ButtonText2 = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
`;

export const BLACK = '#000';
export const GRAY10 = '#191919';
export const GRAY20 = '#333';
export const GRAY30 = '#4d4d4d';
export const GRAY40 = '#666';
export const GRAY50 = '#808080';
export const GRAY60 = '#999';
export const GRAY70 = '#b3b3b3';
export const GRAY80 = '#ccc';
export const GRAY90 = '#e6e6e6';
export const GRAYBG = '#f8f8f8';
export const WHITE = '#fff';
export const BB = '#00428e';
export const BBBright = '#0052b2';
export const BBDARK = '#003572';
export const BBINDIGO = '#152346';
export const BBLight20 = '#3368a5';
export const BBLight80 = '#ccd9e8';
export const BBLight95 = '#f2f6f9';
export const BBPALE = '#e9eff8';
export const SB = '#0080cc';

// RENEWAL

export const ALLSTROKE = '#888';
export const TITLECOLOR = '#FFF';
export const PRODUCTSUB = '#DEDEDE';
export const HASHTAG = '#8f8f8f';
export const MENUBG = 'rgba(29,29,29,0.97)';
export const _216 = '#d8d8d8';

export const HeaderText = styled.span<{ isScrolled: boolean }>`
  font-family: 'Inter', sans-serif;
  /* todo : 완전히 리뉴얼 되면 폰트사이즈 rem으로 조절할것 */
  /* font-size: 1.6rem; */
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0;
  color: #ccc;

  &:hover {
    color: #fff;
  }
  &:hover svg {
    path {
      fill: #fff;
    }
  }

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      color: #b3b3b3;

      &:hover {
        color: ${BB};
      }
      &:hover svg {
        path {
          fill: ${BB};
        }
      }
    `};
`;

export const MenuIntroText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  line-height: 120%;
  font-weight: 700;
  letter-spacing: -0.3px;
`;

export const MenuIntroExplainENText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.2px;
`;
export const MenuIntroExplainKRText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.2px;
`;

export const ThumbnailBottomText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: -0.3px;
`;

export const ProductHeaderText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.3px;
`;

export const MenuNameText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.3px;
`;

export const MenuExplainKRText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 145%;
`;

export const MenuExplainENText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 145%;
`;

export const MobileBtnText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  letter-spacing: -0.01rem;
  line-height: 120%;
  text-transform: uppercase;

  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
`;

export const MobileBtnSubText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.01rem;

  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
`;

export const SNSText = styled.span`
  font-family: 'Inter', sans-serif;
  line-height: 120%;
  font-size: 13px;
`;

export const TitleENText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  line-height: 135%;
  letter-spacing: -0.02rem;
  font-weight: 700;

  // s
  @media screen and (max-width: 904px) {
    font-size: 32px;
    line-height: 125%;
  }

  // xs
  @media screen and (max-width: 599px) {
    font-size: 18px;
    line-height: 135%;
  }
`;

export const TitleKRText = styled(TitleENText)`
  font-family: 'Noto Sans KR', sans-serif;
`;

export const TitleSubText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  line-height: 145%;
  letter-spacing: -0.01rem;
  font-family: 500;

  @media screen and (max-width: 904px) {
    font-size: 16px;
    line-height: 150%;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
    letter-spacing: -0.07rem;
  }
`;

export const TitleBtnText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0;
  font-weight: 500;
`;

export const SubTitleText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.01rem;
  font-weight: 700;

  @media screen and (max-width: 904px) {
    font-size: 24px;
  }

  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
`;

export const ExternalLinkENText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  line-height: 175%;
  letter-spacing: 0;

  @media screen and (max-width: 904px) {
    font-size: 16px;
    line-height: 200%;
    letter-spacing: -0.01rem;
  }
`;

export const ExternalLinkKRText = styled(ExternalLinkENText)`
  font-family: 'Noto Sans KR', sans-serif;
`;

export const AVIFTitleENText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  line-height: 150%;
  letter-spacing: -0.015rem;
  font-weight: 700;

  @media screen and (max-width: 904px) {
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.015rem;
  }

  @media screen and (max-width: 599px) {
    line-height: 105%;
  }
`;
export const AVIFTitleKRText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 40px;
  line-height: 150%;
  letter-spacing: -0.015rem;
  font-weight: 700;

  @media screen and (max-width: 904px) {
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.015rem;
  }

  @media screen and (max-width: 599px) {
    line-height: 105%;
  }
`;

export const AVIFSubENText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0;

  @media screen and (max-width: 904px) {
    line-height: 130%;
  }

  @media screen and (max-width: 599px) {
    line-height: 145%;
  }
`;

export const AVIFSubKRText = styled(AVIFSubENText)`
  font-family: 'Noto Sans KR', sans-serif;
`;

export const TitleSubENText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 145%;
  letter-spacing: -0.01rem;

  @media screen and (max-width: 904px) {
    line-height: 150%;
    font-size: 16px;
  }

  @media screen and (max-width: 599px) {
    line-height: 200%;
    font-size: 12px;
  }
`;

export const TitleSubKRText = styled(TitleSubENText)`
  font-family: 'Noto Sans KR', sans-serif;
`;

export const FunctionNameText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 145%;
  letter-spacing: -0.03rem;
  font-weight: 700;
`;

export const BtnText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 115%;
  letter-spacing: -0.01rem;

  &:active {
    font-weight: 700;
  }
`;

export const EtcText = styled.span`
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  line-height: 170%;
  letter-spacing: -0.03rem;
  font-size: 13px;
`;

export const AVIFHeadText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.015rem;

  @media screen and (max-width: 599px) {
    font-size: 25px;
  }
`;

export const AVIFSubHeadText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: -0.01rem;
  @media screen and (max-width: 599px) {
    font-size: 17px;
  }
`;

export const AVIFDefaultText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 17px;
  line-height: 210%;
  letter-spacing: -0.01rem;
  @media screen and (max-width: 599px) {
    font-size: 13px;
  }
`;

export const AVIFTableHeaderText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: -0.01rem;
  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
`;

export const AVIFTableText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.01rem;
  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
`;

export const AVIFCommandText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 210%;
  letter-spacing: -0.01rem;
  @media screen and (max-width: 599px) {
    font-size: 13px;
  }
`;

export const AVIFCommandSubText = styled.span`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 17px;
  line-height: 210%;
  letter-spacing: -0.01rem;
  @media screen and (max-width: 599px) {
    font-size: 13px;
  }
`;
