import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react';

type State = {
  isPrivatePolicyModalOpen: boolean;
};
type Action = { type: 'close' } | { type: 'open' };

const PrivatePolicyContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: { isPrivatePolicyModalOpen: false },
  dispatch: () => {},
});

const AppContextReducer = (state, action) => {
  switch (action.type) {
    case 'close': {
      return { ...state, isPrivatePolicyModalOpen: false };
    }
    case 'open': {
      return { ...state, isPrivatePolicyModalOpen: true };
    }
    default: {
      throw new Error(`unhandled action type: ${action.type}`);
    }
  }
};

export const PrivatePolicyContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(AppContextReducer, {
    isPrivatePolicyModalOpen: false,
  });
  const value = { state, dispatch };

  return (
    <PrivatePolicyContext.Provider value={value}>
      {children}
    </PrivatePolicyContext.Provider>
  );
};

export const usePrivatePolicyContext = () => {
  const context = useContext(PrivatePolicyContext);

  if (context === undefined)
    throw new Error(
      'use context must be used within a Private Policy Provider.'
    );

  return context;
};
