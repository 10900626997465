import { QueryClient } from 'react-query';

const defaultQueryClientOptions = {
  queries: {
    refetchOnWindowFocus: false,
    staleTime: 30000,
  },
  mutations: {},
};
export const queryClient = new QueryClient({
  defaultOptions: defaultQueryClientOptions,
});

export const queryKeys = {
  postList: 'postList',
  relatedPostList: 'relatedPostList',
  increasePostViewCount: 'increasePostViewCount',
  getTheTwoLatestPosts: 'getTheTwoLatestPosts',
  eventList: 'eventList',
};
