import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react';

type State = {
  isContactModalOpen: boolean;
  isHeaderPopupOpen: boolean;
};
type Action =
  | { type: 'close' }
  | { type: 'open' }
  | { type: 'headerPopupClose' }
  | { type: 'headerPopupOpen' };

const AppContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: { isContactModalOpen: false, isHeaderPopupOpen: true },
  dispatch: () => {},
});

const AppContextReducer = (state, action) => {
  switch (action.type) {
    case 'close': {
      return { ...state, isContactModalOpen: false };
    }
    case 'open': {
      return { ...state, isContactModalOpen: true };
    }
    case 'headerPopupClose': {
      return { ...state, isHeaderPopupOpen: false };
    }
    case 'headerPopupOpen': {
      return { ...state, isHeaderPopupOpen: true };
    }
    default: {
      throw new Error(`unhandled action type: ${action.type}`);
    }
  }
};

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(AppContextReducer, {
    isContactModalOpen: false,
    isHeaderPopupOpen: true,
  });
  const value = { state, dispatch };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined)
    throw new Error('use context must be used within a AppContext Provider.');

  return context;
};
